<template>
  <div>
    <!-- HEADER -->
    <div class="d-flex justify-content-between align-items-center">
      <div class="col-lg-7">
        <h1 class="highlighted-color1 font-size-large wrap-text">
          <span>{{ campaign.name }}</span>
        </h1>
      </div>
      <div class="d-flex">
        <router-link
          :to="{ name: 'campaigns.edit', params: { id: campaign.id } }"
          class="btn btn-sm btn-primary me-2"
          v-if="campaign && campaign.status !== 'FINISHED'"
        >
          Editar campanha
        </router-link>
        <template v-if="campaign.status === 'DRAFT'">
          <Button
            class="btn-sm btn-color1 me-2"
            v-b-modal.destroy-campaign-modal
          >
            Apagar
          </Button>
          <DestroyCampaignModal
            :campaign="campaign"
            @campaign-destroyed="handleDestroyed"
          />
        </template>
        <template v-if="campaign.status !== 'FINISHED'">
          <template v-if="campaign.status === 'ACTIVE'">
            <Button
              class="btn-sm btn-color1 me-2"
              v-b-modal.pause-campaign-modal
            >
              Pausar
            </Button>
            <PauseCampaignModal
              :campaign="campaign"
              @campaign-paused="getCampaign"
            />
          </template>
          <template v-else-if="campaign.status === 'PAUSED'">
            <Button
              class="btn-sm btn-color1 me-2"
              v-b-modal.activate-campaign-modal
            >
              Ativar
            </Button>
            <ActivateCampaignModal
              :campaign="campaign"
              @campaign-activated="getCampaign"
            />
          </template>
        </template>
        <router-link :to="{ name: 'campaigns.index' }" class="btn btn-sm btn-primary">Voltar</router-link>
      </div>
    </div> <!-- END HEADER -->
    <!-- CAMPAIGN -->
    <div class="mt-4">
      <!-- MESSAGES -->
      <div
        class="mb-4 error-message-card text-white font-size-small p-3"
        v-if="hasMessage"
      >
        <p
          class="mb-0"
          v-if="campaign.status === 'ERROR'"
        >
          Ops, houve um erro na criação da campanha. Clique aqui para tentar de novo.
          <span>
            <RetryCampaignCreationOnPlatformButton
              :campaignId="campaign.id"
              @retry-successful="getCampaign"
              @retry-error="handleRetryError"
              color="white"
            />
          </span>
        </p>
        <p
          class="mb-0"
          v-if="campaign.status === 'FINISHED' || campaign.status === 'PAUSED'"
        >
          Campanhas pausadas ou encerradas recentemente possuem um tempo de até 48h para consolidação de métricas e devolução de saldo remanescente para a carteira do anunciante.
        </p>
        <p
          class="mb-0"
          v-if="campaign.available_amount > campaign.amount"
        >
          Campanhas com o saldo recém ajustado possuem um tempo de até 48h para consolidação de métricas e devolução de saldo remanescente para a carteira do anunciante.
        </p>
      </div> <!-- END MESSAGES -->

      <!-- PROCESSING -->
      <div
        class="mb-4 alert-message-card font-size-small p-3"
        v-if="campaign.status === 'PENDING'"
      >
        <p
          class="mb-0"
        >
          <span class="me-1">
            <i class="fa fa-clock-o"></i><i class="fa fa-spin fa-spinner ms-1"></i>
          </span>
          Sua campanha está sendo processada e mandaremos um e-mail quando o processo terminar.
        </p>
      </div> <!-- PROCESSING -->

      <!-- BIG NUMBERS -->
      <div class="mb-5">
        <h2 class="highlighted-color1 font-size-medium">
          <span>Desempenho</span>
        </h2>
        <!-- FILTER -->
        <div class="mb-4">
          <div class="d-flex text-center align-items-center justify-content-center justify-content-lg-end font-size-small default-form">
            Filtrar de
            <DateTimeInput
              v-model.trim="filters.summaryStart"
              format="dd/MM/yyyy"
              type="date"
              theme="basic"
              class="mx-2 form-control-sm-wrapper"
            />
            até
            <DateTimeInput
              v-model.trim="filters.summaryEnd"
              format="dd/MM/yyyy"
              type="date"
              theme="basic"
              class="mx-2 form-control-sm-wrapper"
            />
          </div>
        </div> <!-- END FILTER -->
        <div v-if="summaryLoader" class="text-center">
          <Loader
            :size="'big'"
          />
        </div>
        <CampaignSummary
          :data="summary"
          v-else-if="summary"
        />
      </div> <!-- END BIG NUMBERS -->
      <!-- TABS -->
      <b-tabs content-class="mt-4" nav-class="default-tabs">
        <!-- OVERVIEW TAB -->
        <b-tab title="Dados gerais">
          <div class="mb-5">
            <h2 class="highlighted-color1 font-size-medium mb-4">
              <span>Dados gerais</span>
            </h2>
            <div class="card mb-3">
              <div class="card-body">
                <p>
                  <strong>Anunciante:</strong> <router-link :to="{ name: 'advertisers.show', params: { id: campaign.advertiser.id } }" class="link-color3">{{ campaign.advertiser.name }}</router-link><br/>
                  <strong>Status:</strong> {{ campaign.status | formatCampaignStatus }}<br/>
                  <strong>Início:</strong> {{ campaign.start_date | formatDate }}<br/>
                  <strong>Término:</strong> {{ campaign.end_date | formatDate }}<br/>
                  <strong>Categoria:</strong> {{ campaign.category.name }}<br/>
                  <strong>Orçamento:</strong> {{ campaign.amount / 100 | formatMoney }}<br/>
                  <strong>Meta de CPM:</strong> {{ campaign.cpm_target_amount / 100 | formatMoney }}<br/>
                  <strong>Meta de CPC:</strong> {{ campaign.cpc_target_amount / 100 | formatMoney }}<br/>
                  <strong>Gasto até o momento:</strong> {{ campaign.spending / 100 | formatMoney }}<br/>
                  <strong>Impressões totais:</strong> {{ campaign.impressions | formatNumber }}<br/>
                  <strong>Cliques totais:</strong> {{ campaign.clicks | formatNumber }}<br/>
                  <strong>Conversões totais:</strong> {{ campaign.conversions | formatNumber }}<br/>
                  <strong>CTR:</strong> {{ campaign.ctr | formatNumber }}%<br/>
                  <strong>CPM:</strong> {{ campaign.cpm / 100 | formatMoney }}<br/>
                  <strong>CPA:</strong> {{ campaign.cpa / 100 | formatMoney }}
                </p>
              </div>
            </div>
            <h2 class="highlighted-color1 font-size-medium mb-4">
              <span>Localizações</span>
            </h2>
            <div class="card mb-3">
              <div class="card-body">
                <h2 class="highlighted-color1 font-size-regular mb-3">
                  <span>Estados</span>
                </h2>
                <template v-if="campaign.states.length > 0">
                  <ul class="mb-0">
                    <li
                      v-for="(state, index) in campaign.states"
                      :key="index"
                    >
                      {{ state.name }}
                    </li>
                  </ul>
                </template>
                <template v-else>
                  <p>Sem estados selecionados</p>
                </template>

                <h2 class="highlighted-color1 font-size-regular mb-3 mt-4">
                  <span>Cidades</span>
                </h2>
                <template v-if="campaign.cities.length > 0">
                  <ul class="mb-0">
                    <li
                      v-for="(city, index) in campaign.cities"
                      :key="index"
                    >
                      {{ city.name }} ({{ city.state_short_name }})
                    </li>
                  </ul>
                </template>
                <template v-else>
                  <p>Sem cidades selecionadas</p>
                </template>
              </div>
            </div>
            <h2 class="highlighted-color1 font-size-medium mb-4">
              <span>Browsers</span>
            </h2>
            <div class="card mb-3">
              <div class="card-body">
                <template v-if="campaign.browser.length > 0">
                  <ul class="mb-0">
                    <li
                      v-for="(browser, index) in campaign.browser"
                      :key="index"
                    >
                      {{ browser }}
                    </li>
                  </ul>
                </template>
                <template v-else>
                  <p>Sem browsers selecionados</p>
                </template>
              </div>
            </div>
          </div>
        </b-tab> <!-- END OVERVIEW TAB -->
        <!-- CREATIVES TAB -->
        <b-tab title="Criativos">
          <div>
            <h2 class="highlighted-color1 font-size-medium mb-4">
              <span>Criativos</span>
            </h2>
            <CreativeListTable
              :creatives="creatives"
            />
          </div>
        </b-tab> <!-- END CREATIVES TAB -->
        <!-- TRACKING TAB -->
        <b-tab title="Tracking">
          <div>
            <h2 class="highlighted-color1 font-size-medium mb-4">
              <span>Tracking</span>
            </h2>
            <PixelRuleListTable
              :rules="campaign.pixels"
              :platform="campaign.platform"
              :advertiser="campaign.advertiser"
            />
          </div>
        </b-tab> <!-- END TRACKING -->
      </b-tabs> <!-- END TABS -->
    </div> <!-- END CAMPAIGN -->
  </div>
</template>

<script>

import CampaignsService from '@/modules/campaigns/services/campaigns-service'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import variables from '@/mixins/variables'

import ActivateCampaignModal from '@/components/campaigns/ActivateCampaignModal'
import Button from '@/components/common/Button/Button'
import CampaignSummary from '@/components/campaigns/CampaignSummary'
import CreativeListTable from '@/components/creatives/CreativeListTable'
import DateTimeInput from '@/components/common/Input/DateTimeInput'
import DestroyCampaignModal from '@/components/campaigns/DestroyCampaignModal'
import Loader from '@/components/common/Loader/Loader'
import PauseCampaignModal from '@/components/campaigns/PauseCampaignModal'
import PixelRuleListTable from '@/components/pixels/PixelRuleListTable'
import RetryCampaignCreationOnPlatformButton from '@/components/campaigns/RetryCampaignCreationOnPlatformButton'

export default {
  props: ['baseCampaign'],
  mixins: [variables],
  components: {
    ActivateCampaignModal,
    Button,
    CampaignSummary,
    CreativeListTable,
    DateTimeInput,
    DestroyCampaignModal,
    Loader,
    PauseCampaignModal,
    PixelRuleListTable,
    RetryCampaignCreationOnPlatformButton
  },
  data () {
    return {
      campaign: null,
      filters: {
        status: '',
        summaryStart: '',
        summaryEnd: ''
      },
      loader: false,
      summary: null,
      summaryLoader: true
    }
  },
  created () {
    this.campaign = this.baseCampaign
    this.filters.summaryStart = moment({ hour: 0, minute: 0, seconds: 0 }).subtract(90, 'days').format()
    this.filters.summaryEnd = moment({ hour: 0, minute: 0, seconds: 0 }).format()
    this.getCampaignSummary()
  },
  watch: {
    'filters.summaryStart': function (newValue, oldValue) {
      if (!oldValue) return
      const newV = moment(newValue)
      const oldV = moment(oldValue)
      if (!newV.isSame(oldV)) {
        this.getCampaignSummary()
      }
    },
    'filters.summaryEnd': function (newValue, oldValue) {
      if (!oldValue) return
      const newV = moment(newValue)
      const oldV = moment(oldValue)
      if (!newV.isSame(oldV)) {
        this.getCampaignSummary()
      }
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    }),
    ...mapState('campaigns', {
      currentCampaign: state => state.currentCampaign
    }),
    /**
     * Creatives
     */
    creatives () {
      let array = []
      if (this.campaign.creatives.creatives.length > 0) array = [...array, ...this.campaign.creatives.creatives]
      if (this.campaign.creatives.square_creatives.length > 0) array = [...array, ...this.campaign.creatives.square_creatives]
      return array
    },
    /**
     * Check if has message to show user
     */
    hasMessage () {
      if (!this.campaign) return false
      return this.campaign.status === 'FINISHED' || this.campaign.status === 'PAUSED' || this.campaign.status === 'ERROR' || this.campaign.available_amount > this.campaign.amount
    }
  },
  methods: {
    ...mapActions('campaigns', [
      'setCurrentCampaign'
    ]),
    /**
     * Get advertiser
     */
    async getCampaign () {
      this.loader = true
      try {
        const campaign = await CampaignsService.getCampaign(this.$route.params.id)
        this.campaign = campaign
        this.setCurrentCampaign({ campaign: campaign })
        this.getCampaignSummary()
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
        this.$router.push('/')
      }
      this.loader = false
    },
    /**
     * Get campaign summary
     */
    async getCampaignSummary () {
      this.summaryLoader = true
      try {
        const summary = await CampaignsService.getCampaignsSummary({
          campaignIds: [this.campaign.id],
          startDate: moment(this.filters.summaryStart).format('YYYY-MM-DD'),
          endDate: moment(this.filters.summaryEnd).format('YYYY-MM-DD')
        })
        this.summary = summary
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.summaryLoader = false
    },
    /**
     * Handle destroyed
     */
    handleDestroyed () {
      this.$router.push({ name: 'campaigns.index' })
    },
    /**
     * Handle retry error
     */
    handleRetryError (error) {
      error.handleGlobally && error.handleGlobally()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap-text {
  width: 100%;
  word-wrap: break-word;
}
</style>
