<template>
  <div>
    <!-- MAIN LOADER -->
    <template v-if="loader && !campaign">
        <Loader
          :size="'big'"
        />
    </template> <!-- END MAIN LOADER -->
    <template v-else-if="campaign">
      <NativeCampaignShow
        :baseCampaign="campaign"
        v-if="campaign.type === 'NATIVE'"
      />
      <ProgramaticCampaignShow
        :baseCampaign="campaign"
        v-if="campaign.type === 'PROGRAMATIC'"
      />
    </template>
  </div>
</template>

<script>

import CampaignsService from '@/modules/campaigns/services/campaigns-service'
import { mapActions, mapState } from 'vuex'
import variables from '@/mixins/variables'

import Loader from '@/components/common/Loader/Loader'
import NativeCampaignShow from '@/modules/campaigns/views/native/NativeCampaignShow'
import ProgramaticCampaignShow from '@/modules/campaigns/views/programatic/ProgramaticCampaignShow'

export default {
  mixins: [variables],
  components: {
    Loader,
    NativeCampaignShow,
    ProgramaticCampaignShow
  },
  data () {
    return {
      campaign: null,
      loader: false
    }
  },
  metaInfo () {
    return {
      title: this.campaign ? this.campaign.name : ''
    }
  },
  created () {
    this.getCampaign()
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    }),
    ...mapState('campaigns', {
      currentCampaign: state => state.currentCampaign
    })
  },
  methods: {
    ...mapActions('campaigns', [
      'setCurrentCampaign'
    ]),
    /**
     * Get advertiser
     */
    async getCampaign () {
      this.loader = true
      try {
        const campaign = await CampaignsService.getCampaign(this.$route.params.id)
        this.campaign = campaign
        this.setCurrentCampaign({ campaign: campaign })
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
        this.$router.push('/')
      }
      this.loader = false
    }
  }
}
</script>
