var variables = {
  data () {
    return {
      paymentStatus: [
        {
          key: 'PAID',
          value: 'Pago'
        },
        {
          key: 'PENDING',
          value: 'Pendente'
        },
        {
          key: 'PROCESSING',
          value: 'Processando'
        },
        {
          key: 'AUTHORIZED',
          value: 'Autorizado'
        },
        {
          key: 'REFUNDED',
          value: 'Estornado'
        },
        {
          key: 'WAITING_PAYMENT',
          value: 'Aguardando pagamento'
        },
        {
          key: 'PENDING_REFUND',
          value: 'Aguardando estorno'
        },
        {
          key: 'REFUSED',
          value: 'Recusado'
        },
        {
          key: 'CHARGEDBACK',
          value: 'Chargeback'
        },
        {
          key: 'EXPIRED',
          value: 'Expirado'
        },
        {
          key: 'CANCELED',
          value: 'Cancelado'
        }
      ],
      tooltips: {
        billing_profile: 'Perfil de cobrança se refere aos dados utilizados para gerar notas fiscais. Selecione "Anunciante" caso queira que sejam os usados os dados cadastrados do anunciante ou "Empresa" para utilizar os dados da empresa disponíveis em "Minha Empresa".',
        lines: 'A segmentação é o conjunto de configurações que você pré determina para direcionar sua entrega. As segmentações mais comuns são dados demográficos, Wish e block lists, audiencias e praças.',
        pixels: 'Os pixels auxiliam na mensuração do desempenho da sua campanha e na criação de públicos de Remarketing. Utilizando pixelsl é possível contabilizar as ações realizadas em seu site após o click no anúncio e também mensurar quais anúncios e segmentações estão gerando mais conversões.',
        creatives: 'Criativo são os anúncios que você utilizará em sua campanha. Recomendamos a utilização de um banner 300x250 para melhorar o alcance da sua campanha.',
        site_lists: 'Wishlist, em tradução livre é "lista de desejo", ou seja, sua lista de domínios da web onde deseja que sua campanha apareça. Blocklist, em tradução livre é "lista de bloqueio", ou seja, ao contrário do tópico acima, uma lista de domínios da web onde deseja que sua campanha não seja exibida.',
        locations: 'Praças são as regiões que você gostaria de entregar os seus anúncios. Normalmente utilizamos para segmentar a mensagem para o público alvo que escolhemos.',
        audiences: 'Nesse grupo você vai encontrar milhares de audiências que só estão disponíveis na mídia programática. Não existe um limite de audiências, quanto mais forem selecionadas, maior a chance de impactar mais pessoas. Procure em português, inglês e com termos diversos. Recomendamos que você utilize mais de duas audiências por estratégia para garantir um bom alcance para sua campanha. Você vai reparar que vários parceiros de dados como SERASA, oferecem audiências por aqui.',
        platform_audiences: 'São audiências com alta probabilidade de boa entrega e resultados. Elas são divididas em dois grupos principais: Affinity Audience, que considera grupos com afinidades em comum como "fashion", "food". São audiências muito boas para gerar impacto e awareness para a marca. Outro grupo são as In Market Audience, que consideram pessoas que estão ativamente procurando um serviço ou produto online. São boas para gerar negócios e vendas. Recomendamos que sua campanha tenha, pelo menos, uma audiência recomendada',
        packages: 'Os grupos de campanha servem para que você agrupe as estratégias de acordo com sua necessidade. Utilize grupos de campanha para direcionar a entrega para um determinado público, praça ou site. Você também pode utilizar grupos de campanha para facilitar a distribuição de verba entre as estratégias e assim facilitar o dia a dia de otimizações.'
      }
    }
  }
}

export default variables
