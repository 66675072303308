<template>
  <b-modal
    id="new-campaign-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>Crie um grupo de campanha</span> <span v-b-tooltip.hover :title="tooltips.packages"><i class="fa fa-info-circle font-size-medium"></i></span>
      </h2>
    </div>
    <div class="text-center mt-3">
      <p>Parabéns pela nova campanha!</p>
      <p>Agora, o próximo passo é criar um grupo de campanha.</p>
      <p>Clique no link abaixo para ser levado para a página de criação!</p>
      <router-link
        class="btn btn-primary mt-4"
        :to="{ name: 'packages.create', params: { cId: campaign ? campaign.id : '' } }"
      >
        Criar um grupo
      </router-link>
    </div>
  </b-modal>
</template>

<script>

import variables from '@/mixins/variables'

export default {
  name: 'NewCampaignModal',
  mixins: [variables],
  props: ['campaign']
}
</script>
